import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Para navegação
import './SideBarInd.css';
import logoAkvofluo from '../../assets/images/logo_Akvofluo_para_Fundo_Escuro.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTable,
  faFileAlt,
  faCamera,
  faWater,
  faChevronRight,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';

const SidebarInd = () => {
  const [isExpandedHistoricalData, setIsExpandedHistoricalData] = useState(false);
  const navigate = useNavigate();

  // Alternar a expansão do menu de Dados Históricos
  const toggleExpandHistoricalData = () => setIsExpandedHistoricalData(!isExpandedHistoricalData);

  // Função para navegação
  const handleNavigation = (path) => {
    navigate(path); // Redireciona para a rota correspondente
  };

  return (
    <div className="sidebar">
      {/* Logo */}
      <a className="sidebar-logo-link" href="https://akvofluo.com.br/">
        <img src={logoAkvofluo} alt="Logo Akvofluo" className="sidebar-logo" />
      </a>
      <div className="sidebar-divider"></div>

      {/* Menu Dados Históricos */}
      <div className="sidebar-item" onClick={toggleExpandHistoricalData}>
        <FontAwesomeIcon icon={faTable} className="sidebar-item-icon" />
        Dados Históricos
        <FontAwesomeIcon
          icon={isExpandedHistoricalData ? faChevronDown : faChevronRight}
          className="sidebar-expand-icon"
        />
      </div>

      {/* Subitens de Dados Históricos */}
      {isExpandedHistoricalData && (
        <div className="sidebar-subitems">
          <div className="sidebar-subitem" onClick={() => handleNavigation('/historico-leituras')}>
            Leituras
          </div>
          <div className="sidebar-subitem" onClick={() => handleNavigation('/historico-fotos')}>
            Fotos
          </div>
          <div className="sidebar-subitem" onClick={() => handleNavigation('/historico-relatorios')}>
            Relatórios
          </div>
          <div className="sidebar-subitem" onClick={() => handleNavigation('/historico-auditorias')}>
            Auditorias
          </div>
        </div>
      )}

      <div className="sidebar-divider"></div>

      {/* Opção: Inserção de Contas Mensais */}
      <div className="sidebar-item" onClick={() => handleNavigation('/inserir-conta-nova')}>
        <FontAwesomeIcon icon={faFileAlt} className="sidebar-item-icon" />
        Inserção de Contas Mensais
      </div>
      <div className="sidebar-divider"></div>

       {/* Opção: Inserir Fotos */}
       <div className="sidebar-item" onClick={() => handleNavigation('/inserir-fotos')}>
        <FontAwesomeIcon icon={faCamera} className="sidebar-item-icon" />
        Inserir Fotos
      </div>
      <div className="sidebar-divider"></div>

      {/* Opção: Inserir Leituras */}
      <div className="sidebar-item" onClick={() => handleNavigation('/inserir-leitura-ind')}>
        <FontAwesomeIcon icon={faWater} className="sidebar-item-icon" />
        Inserir Leituras
      </div>
    


    </div>
  );
};

export default SidebarInd;
