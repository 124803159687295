import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Dropdown from '../../components/Dropdown/Dropdown';
import LabelWithTooltipAndAsterisk from '../../components/Tooltip/Tooltip'; // Wrapper para Tooltip e Asterisco
import tooltipImage from '../../assets/example-image.jpg'; // Exemplo de imagem
import './InsertNewBillPage.css';
import axios from 'axios'; // Para fazer requisições HTTP

const InsertNewBillPage = () => {
  const [selectedClient, setSelectedClient] = useState('');
  const [selectedPoint, setSelectedPoint] = useState('');
  const [readingDate, setReadingDate] = useState(new Date());
  const [consumedVolume, setConsumedVolume] = useState('');
  const [billAmount, setBillAmount] = useState('');
  const [competenceDate, setCompetenceDate] = useState(new Date());
  const [lastReadingDate, setLastReadingDate] = useState(new Date()); // Nova data: última leitura
  const [nextReadingDate, setNextReadingDate] = useState(new Date()); // Nova data: próxima leitura
  const [readingValue, setReadingValue] = useState(''); // Novo campo: leitura (float)
  const [clients, setClients] = useState([]); // Lista de clientes
  const [points, setPoints] = useState([]); // Lista de pontos de medição

  // Buscar a lista de clientes ao carregar o componente
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get('https://api.akvofluo.com/api/clients/');
        setClients(response.data.results); // Supondo que a API retorne um array de clientes
      } catch (error) {
        console.error('Erro ao buscar clientes:', error);
      }
    };

    fetchClients();
  }, []);

  // Buscar os pontos de medição quando o cliente for selecionado
  useEffect(() => {
    const fetchPoints = async () => {
      if (selectedClient) {
        try {
          const response = await axios.get(
            `https://api.akvofluo.com/api/pontos/cliente/?client_id=${selectedClient}`
          );
          setPoints(response.data.results); // Supondo que a API retorne um array de pontos de medição
        } catch (error) {
          console.error('Erro ao buscar pontos de medição:', error);
        }
      } else {
        setPoints([]); // Limpa os pontos se nenhum cliente estiver selecionado
      }
    };

    fetchPoints();
  }, [selectedClient]);

  const handleClientChange = (e) => {
    setSelectedClient(e.target.value);
    setSelectedPoint(''); // Limpa o ponto selecionado ao mudar o cliente
  };

  const handlePointChange = (e) => {
    setSelectedPoint(e.target.value);
  };

  const handleInsertBill = async () => {
    if (
      !selectedClient ||
      !selectedPoint ||
      !readingDate ||
      !consumedVolume ||
      !billAmount ||
      !competenceDate ||
      !lastReadingDate ||
      !nextReadingDate ||
      !readingValue
    ) {
      alert('Preencha todos os campos.');
      return;
    }

    // Dados a serem enviados para a API
    const billData = {
      measure_point: selectedPoint,
      date_actual_reading: readingDate.toISOString().split('T')[0], // Formato YYYY-MM-DD
      volume: parseFloat(consumedVolume),
      value: parseFloat(billAmount),
      period: competenceDate.toISOString().split('T')[0], // Formato YYYY-MM-DD
      date_last_reading: lastReadingDate.toISOString().split('T')[0], // Formato YYYY-MM-DD
      date_next_reading: nextReadingDate.toISOString().split('T')[0], // Formato YYYY-MM-DD
      reading: parseFloat(readingValue), // Converte para float
    };

    try {
      // Enviar os dados para a API
      const response = await axios.post('https://api.akvofluo.com/api/contas-de-agua/', billData);

      if (response.status === 201) {
        alert('Conta inserida com sucesso!');
        // Limpar o formulário após o sucesso (opcional)
        setSelectedClient('');
        setSelectedPoint('');
        setReadingDate(new Date());
        setConsumedVolume('');
        setBillAmount('');
        setCompetenceDate(new Date());
        setLastReadingDate(new Date());
        setNextReadingDate(new Date());
        setReadingValue('');
      }
    } catch (error) {
      console.error('Erro ao inserir conta:', error);
      alert('Erro ao inserir conta. Verifique os dados e tente novamente.');
    }
  };

  return (
    <div className="insert-bill-page">
      <h2>Inserção de Conta Nova</h2>
      <div className="bill-container">
        <div className="form-group">
          {/* Dropdown de Clientes */}
          <Dropdown
            label="Selecione o Cliente"
            options={clients.map((client) => ({
              value: client.id,
              label: client.name, // Supondo que o cliente tenha um campo "name"
            }))}
            value={selectedClient}
            onChange={handleClientChange}
            required={true}
          />

          {/* Dropdown de Pontos de Medição */}
          <Dropdown
            label="Selecione o Ponto de Medição"
            options={points.map((point) => ({
              value: point.id,
              label: point.name, // Supondo que o ponto de medição tenha um campo "name"
            }))}
            value={selectedPoint}
            onChange={handlePointChange}
            required={true}
            disabled={!selectedClient} // Desabilita se nenhum cliente estiver selecionado
          />

          {/* Input Data de Leitura com Tooltip */}
          <LabelWithTooltipAndAsterisk
            label="Data de Leitura"
            tooltipImage={tooltipImage}
            required={true}
          >
            <DatePicker
              selected={readingDate}
              onChange={(date) => setReadingDate(date)}
              dateFormat="dd/MM/yyyy"
              className="custom-datepicker-input"
            />
          </LabelWithTooltipAndAsterisk>

          {/* Input Volume Consumido com Tooltip */}
          <LabelWithTooltipAndAsterisk
            label="Volume Consumido (m³)"
            tooltipImage={tooltipImage}
            required={true}
          >
            <input
              type="number"
              value={consumedVolume}
              onChange={(e) => setConsumedVolume(e.target.value)}
              placeholder="Digite o volume consumido"
              className="custom-input"
            />
          </LabelWithTooltipAndAsterisk>

          {/* Input Valor da Conta com Tooltip */}
          <LabelWithTooltipAndAsterisk
            label="Valor da Conta (R$)"
            tooltipImage={tooltipImage}
            required={true}
          >
            <input
              type="number"
              value={billAmount}
              onChange={(e) => setBillAmount(e.target.value)}
              placeholder="Digite o valor da conta"
              className="custom-input"
            />
          </LabelWithTooltipAndAsterisk>

          {/* Competência com Tooltip */}
          <LabelWithTooltipAndAsterisk
            label="Competência (Mês/Ano)"
            tooltipImage={tooltipImage}
            required={true}
          >
            <DatePicker
              selected={competenceDate}
              onChange={(date) => setCompetenceDate(date)}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              className="custom-datepicker-input"
            />
          </LabelWithTooltipAndAsterisk>

          {/* Nova Data: Última Leitura */}
          <LabelWithTooltipAndAsterisk
            label="Data Última Leitura"
            tooltipImage={tooltipImage}
            required={true}
          >
            <DatePicker
              selected={lastReadingDate}
              onChange={(date) => setLastReadingDate(date)}
              dateFormat="dd/MM/yyyy"
              className="custom-datepicker-input"
            />
          </LabelWithTooltipAndAsterisk>

          {/* Nova Data: Próxima Leitura */}
          <LabelWithTooltipAndAsterisk
            label="Data Próxima Leitura"
            tooltipImage={tooltipImage}
            required={true}
          >
            <DatePicker
              selected={nextReadingDate}
              onChange={(date) => setNextReadingDate(date)}
              dateFormat="dd/MM/yyyy"
              className="custom-datepicker-input"
            />
          </LabelWithTooltipAndAsterisk>

          {/* Novo Campo: Leitura (Float) */}
          <LabelWithTooltipAndAsterisk
            label="Leitura (m³)"
            tooltipImage={tooltipImage}
            required={true}
          >
            <input
              type="number"
              step="0.01" // Permite valores decimais
              value={readingValue}
              onChange={(e) => setReadingValue(e.target.value)}
              placeholder="Digite o valor da leitura"
              className="custom-input"
            />
          </LabelWithTooltipAndAsterisk>
        </div>

        <button className="insert-bill-button" onClick={handleInsertBill}>
          Inserir Conta
        </button>
      </div>
    </div>
  );
};

export default InsertNewBillPage;