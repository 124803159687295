import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./ReadingSearchPage.css";

const ReadingSearchPage = () => {
  const [clients, setClients] = useState([]); // Lista de clientes
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date()); // Data inicial (mês atual)
  const [readings, setReadings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // Buscar clientes da API ao carregar o componente
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await fetch("https://api.akvofluo.com/api/clients");
        if (!response.ok) {
          throw new Error("Erro ao buscar clientes.");
        }
        const data = await response.json();
        setClients(data); // Salva os clientes no estado
      } catch (err) {
        console.error(err.message);
        setError("Não foi possível carregar os clientes.");
      }
    };

    fetchClients();
  }, []);

  // Buscar leituras da API
  const handleSearch = async () => {
    if (!selectedClient) {
      alert("Por favor, selecione um cliente.");
      return;
    }

    setLoading(true);
    setError("");
    setReadings([]);

    const year = selectedDate.getFullYear();
    const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0");

    try {
      const response = await fetch(
        `https://api.akvofluo.com/api/individualized-reading/?client=${selectedClient}&year=${year}&month=${month}`
      );
      if (!response.ok) {
        throw new Error("Erro ao buscar leituras. Por favor, tente novamente.");
      }

      const data = await response.json();
      setReadings(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="reading-search-page">
      <h2>Buscar Leituras</h2>
      <div className="form-group">
        {/* Dropdown de Clientes */}
        <label>Selecione o Cliente</label>
        <select
          value={selectedClient}
          onChange={(e) => setSelectedClient(e.target.value)}
        >
          <option value="">-- Selecione um Cliente --</option>
          {clients.map((client) => (
            <option key={client.id} value={client.id}>
              {client.name}
            </option>
          ))}
        </select>

        {/* DatePicker para selecionar mês e ano */}
        <label>Selecione o Mês e Ano</label>
        <DatePicker
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          dateFormat="MM/yyyy"
          showMonthYearPicker
        />

        <button onClick={handleSearch} className="search-button">
          Buscar Leituras
        </button>
      </div>

      {/* Indicadores de carregamento e erro */}
      {loading && <p>Carregando...</p>}
      {error && <p className="error">{error}</p>}

      {/* Tabela de Resultados */}
      {readings.length > 0 && (
        <table className="readings-table">
          <thead>
            <tr>
              <th>Unidade</th>
              <th>Data</th>
              <th>Tipo</th>
              <th>Valor</th>
            </tr>
          </thead>
          <tbody>
            {readings.map((reading, index) => (
              <tr key={index}>
                <td>{reading.unity}</td>
                <td>{reading.date}</td>
                <td>{reading.hot_or_cold === "hot" ? "Quente" : "Fria"}</td>
                <td>{reading.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Mensagem de nenhum resultado encontrado */}
      {readings.length === 0 && !loading && !error && (
        <p>Nenhuma leitura encontrada para os critérios selecionados.</p>
      )}
    </div>
  );
};

export default ReadingSearchPage;
