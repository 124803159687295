import React, { useState, useEffect, forwardRef } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';

import './PhotoUploadPage.css';

const PhotoUploadPage = () => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [selectedClientData, setSelectedClientData] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [batchSize, setBatchSize] = useState(50);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [uploadDate, setUploadDate] = useState(new Date());
  const [isDragging, setIsDragging] = useState(false);

  const clientsUrl = 'https://api.akvofluo.com/api/clients/';
  const uploadUrl = 'https://api.akvofluo.com/api/upload/list/';

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get(clientsUrl);
        setClients(response.data.results);
      } catch (err) {
        console.error('Erro ao buscar clientes:', err);
      }
    };
    fetchClients();
  }, []);

  const handleClientChange = (e) => {
    const clientId = e.target.value;
    setSelectedClient(clientId);

    const clientData = clients.find(client => client.id.toString() === clientId);
    setSelectedClientData(clientData || null);
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setPhotos((prevPhotos) => [...prevPhotos, ...files]);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    if (e.dataTransfer.files.length > 0) {
      const files = Array.from(e.dataTransfer.files);
      const imageFiles = files.filter((file) => file.type.startsWith("image/"));

      if (imageFiles.length > 0) {
        setPhotos((prevPhotos) => [...prevPhotos, ...imageFiles]);
      } else {
        setError("Apenas arquivos de imagem são permitidos.");
      }
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleUpload = async () => {
    if (!selectedClient || photos.length === 0) {
      setError('Selecione um cliente e carregue ao menos uma foto.');
      return;
    }

    setError('');
    setSuccessMessage('');
    const batches = [];

    const formattedDate = format(uploadDate, 'yyyy-MM');

    for (let i = 0; i < photos.length; i += batchSize) {
      batches.push(photos.slice(i, i + batchSize));
    }
    console.log(batches)
    for (const [index, batch] of batches.entries()) {
      const formData = new FormData();
      formData.append('client', selectedClient);
      formData.append('upload_date', formattedDate);
      batch.forEach((photo) => {
        formData.append('images', photo); // Use 'images' como nome do campo
        
      });

    // Inspecionar o conteúdo do FormData
    /*for (const [key, value] of formData.entries()) {
      console.log(key, value);
    }*/
      try {
        const response = await axios.post(uploadUrl, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        console.log(response.data)
        console.log(`Batch ${index + 1} enviado com sucesso:`, response.data);
      } catch (err) {
        console.error(`Erro ao enviar batch ${index + 1}:`, err);
        setError('Erro ao enviar algumas fotos. Verifique a conexão e tente novamente.');
        return;
      }
    }

    setSuccessMessage('Todas as fotos foram enviadas com sucesso!');
    setPhotos([]);
  };

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="datepicker-button" onClick={onClick} ref={ref}>
      📅 {value || "Selecione Mês e Ano"}
    </button>
  ));

  return (
    <div className="photo-upload-page">
      <div className="photo-container">
        <h2>Inserção de Fotos</h2>
        <div className="form-group">
          <label htmlFor="clientDropdown">Selecione o Cliente:</label>
          <select
            id="clientDropdown"
            className="dropdown"
            value={selectedClient}
            onChange={handleClientChange}
          >
            <option value="">Selecione...</option>
            {clients.map((client) => (
              <option key={client.id} value={client.id}>
                {client.name}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label>Escolha o Mês e Ano da Foto:</label>
          <DatePicker
            selected={uploadDate}
            onChange={(date) => setUploadDate(date)}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            customInput={<CustomInput />}
          />
        </div>
        
        <div
          className={`drag-drop-area ${isDragging ? 'dragging' : ''}`}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
        >
          <p>Arraste e solte as fotos aqui ou clique abaixo para carregar</p>
          <input
            type="file"
            id="photoUpload"
            multiple
            accept="image/*"
            className="custom-file-input"
            onChange={handleFileChange}
          />
        </div>
        <div className="photo-info">
          {photos.length > 0 && selectedClientData && (
            <p>
              📸 {photos.length} fotos detectadas.  
              <br />
              🔍 Cliente: <strong>{selectedClientData.name}</strong> tem <strong>{selectedClientData.num_units}</strong> unidades.
            </p>
          )}
        </div>
        <div className="sticky-header">
          <button className="upload-button" onClick={handleUpload}>
            Enviar Fotos
          </button>
        </div>

        {/* Exibição das fotos carregadas */}
        <div className="photo-preview">
          {photos.length > 0 && (
            <>
              <h3>Fotos selecionadas ({photos.length}):</h3>
              <div className="preview-grid">
                {photos.map((photo, index) => (
                  <div key={index} className="photo-item">
                    <img src={URL.createObjectURL(photo)} alt={`preview ${index}`} />
                    <p>{photo.name}</p>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>

        

        {error && <p className="error">{error}</p>}
        {successMessage && <p className="success">{successMessage}</p>}
      </div>
    </div>
  );
};

export default PhotoUploadPage;
