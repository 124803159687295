import React, { useEffect, useState } from 'react';
import apiClient from '../../api/apiClient';

const ClientDropdown = () => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');

  useEffect(() => {
    const fetchClients = async () => {
      try {
        let allClients = [];
        let nextPage = "/api/clients/";
    
        while (nextPage) {
          const response = await apiClient.get(nextPage);
          console.log(response.data);
          allClients = [...allClients, ...response.data.results];
          nextPage = response.data.next; // Se houver mais páginas, continua buscando
        }
    
        setClients(allClients);
      } catch (error) {
        console.error("Erro ao buscar clientes:", error);
    };
    
  }
    fetchClients();
  }, []);

  return (
    <div>
      <label>Selecione o Cliente</label>
      <select
        value={selectedClient}
        onChange={(e) => setSelectedClient(e.target.value)}
      >
        <option value="">Escolha um cliente</option>
        {clients.map((client) => (
          <option key={client.id} value={client.id}>
            {client.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default ClientDropdown;
