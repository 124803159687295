import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import PrivateRoute from './PrivateRoute';
import IndividualizedLayout from './layouts/IndvidualizedLayout';
import HomePage from './pages/HomePage/HomePage';
import LoginPage from './pages/LoginPage/LoginPage';
import RegisterPage from './pages/RegisterPage/RegisterPage';
import DashBoard from './pages/Dashboard/Dashboard';
import UploadImgPage from './pages/UploadImgPage/UploadImgPage';
import WaterMeterReadings from './pages/WaterMeterReadings/WaterMeterReadings';
import InsertReadingPage from './pages/InsertReading/InsertReading';
import InsertOldBillPage from './pages/InsertOldBillPage/InsertOldBillPage';
import InsertNewBillPage from './pages/InsertNewBillPage/InsertNewBillPage';
import ConsumptionTablePage from './pages/ConsumptionTablePage/ConsumptionTablePage';
import ConsumptionHistoryPage from './pages/ConsumptionHistoryPage/ConsumptionHistoryPage';
import OldBillsPage from './pages/OldBillsPage/OldBillsPage';
import NewBillsPage from './pages/NewBillsPage/NewBillsPage';
import EditEmailPage from './pages/EditEmailPage/EditEmailPage';
import ChangePasswordPage from './pages/ChangePasswordPage/ChangePasswordPage';
import InsertIndReadingPage from './pages/InsertIndReadingPage/InsertIndReadingPage';
import PaymentHistoryPage from './pages/PaymentHistoryPage/PaymentHistoryPage';
import PaymentInstructionsPage from './pages/PaymentInstructionsPage/PaymentInstructionsPage';
import LinkGenerationPage from './pages/LinkGenerationPage/LinkGenerationPage';
import InstructionsPage from './pages/InstructionsPage/InstructionsPage';
import AddressPage from './pages/AddressPage/AddressPage';
import PublicLayout from './layouts/PublicLayout';
import PrivateLayout from './layouts/PrivateLayout';
import RegisterClientPage from './pages/RegisterClientPage/RegisterClientPage';
import RegisterManagerPage from './pages/ManagerRegisterPage/ManagerRegisterPage';
import ReadingSearchPage from './pages/ReadingSearchPage/ReadingSearchPage';
import PhotoUploadPage from './pages/PhotoUploadPage/PhotoUploadPage';
import ClientDropdown from './components/ClientDropDown/ClientDropDown';

function App() {
    return (
        <AuthProvider>
            <Routes>
                {/* Rotas Públicas */}
                <Route element={<PublicLayout />}>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/register" element={<RegisterPage />} />
                    <Route path="/register-client/:managerId" element={<RegisterClientPage />} />
                    <Route path="/register-manager/:userId" element={<RegisterManagerPage />} />
                </Route>

                {/* Rotas Privadas */}
                <Route
                    element={
                        <PrivateRoute>
                            <PrivateLayout />
                        </PrivateRoute>
                    }
                >
                    <Route path="/dashboard" element={<DashBoard />} />
                    <Route path="/upload-fotos" element={<UploadImgPage />} />
                    <Route path="/inserir-leitura" element={<InsertReadingPage />} />
                    <Route path="/inserir-conta-antiga" element={<InsertOldBillPage />} />
                    <Route path="/leitura-tabela" element={<ConsumptionTablePage />} />
                    <Route path="/historico-consumo" element={<ConsumptionHistoryPage />} />
                    <Route path="/contas-antigas" element={<OldBillsPage />} />
                    <Route path="/contas-novas" element={<NewBillsPage />} />
                    <Route path="/alterar-email" element={<EditEmailPage />} />
                    <Route path="/alterar-senha" element={<ChangePasswordPage />} />
                    <Route path="/historico-pagamento" element={<PaymentHistoryPage />} />
                    <Route path="/instrucoes-pagamento" element={<PaymentInstructionsPage />} />
                    <Route path="/geracao-links" element={<LinkGenerationPage />} />
                    <Route path="/instrucoes" element={<InstructionsPage />} />
                    <Route path="/endereco" element={<AddressPage />} />
                    
                    

                </Route>
                {/* rotas de individualizaçao alternativa*/}
                
                <Route
                    element={
                
                            <IndividualizedLayout/>
                
                    }ReadingSearchPage
                >
                    <Route path="/leitura-individualizada" element={<WaterMeterReadings />} />
                    <Route path="/inserir-conta-nova" element={<InsertNewBillPage />} />
                    <Route path="/historico-leituras" element={<ReadingSearchPage />} />
                    <Route path="/inserir-leitura-ind" element={<InsertIndReadingPage />} />
                    <Route path="/inserir-fotos" element={<PhotoUploadPage />} />
                    <Route path="/testeclient" element={<ClientDropdown />} />
                </Route>
            </Routes>
        </AuthProvider>
    );
}

export default App;

