import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Dropdown from '../../components/Dropdown/Dropdown';
import './InsertIndReadingPage.css';

const InsertIndReadingPage = () => {
  const [selectedClient, setSelectedClient] = useState('');
  const [readings, setReadings] = useState([]);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);

  // Buscar a lista de clientes ao carregar o componente
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get('https://api.akvofluo.com/api/clients/');
        setClients(response.data.results);
      } catch (error) {
        console.error('Erro ao buscar clientes:', error);
      }
    };

    fetchClients();
  }, []);

  // Buscar as leituras quando o cliente for selecionado
  useEffect(() => {
    const fetchReadings = async () => {
      if (!selectedClient) return;

      setLoading(true);
      setReadings([]);

      try {
        let allReadings = [];
        let nextUrl = `https://api.akvofluo.com/api/individualized-reading/?client_id=${selectedClient}&date=${getPreviousMonthDate()}`;

        // Loop para percorrer todas as páginas
        while (nextUrl) {
          const response = await axios.get(nextUrl, {
            headers: {
              'Accept': 'application/json', // Solicita JSON explicitamente
            },
          });

          if (response.data.results && response.data.results.length > 0) {
            allReadings = [...allReadings, ...response.data.results]; // Adiciona os resultados à lista
          }

          // Atualiza a URL da próxima página
          nextUrl = response.data.next;
        }

        // Ordena as leituras por "unity" e "block"
        const sortedReadings = allReadings.sort((a, b) => {
          if (a.unity === b.unity) {
            return a.block.localeCompare(b.block);
          }
          return a.unity.localeCompare(b.unity);
        });

        setReadings(sortedReadings);
        console.log('Total de leituras encontradas:', sortedReadings.length);
      } catch (error) {
        console.error('Erro ao buscar leituras:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchReadings();
  }, [selectedClient]);

  // Função para obter a data do mês anterior no formato YYYY/MM/01
  const getPreviousMonthDate = () => {
    const today = new Date();
    const previousMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const year = previousMonth.getFullYear();
    const month = String(previousMonth.getMonth() + 1).padStart(2, '0');
    return `${year}-${month}-01`;
  };

  const handleClientChange = (e) => {
    setSelectedClient(e.target.value);
  };

  return (
    <div className="insert-readings-page">
      <h2>Inserção de Leituras</h2>
      <div className="readings-container">
        <div className="form-group">
          <Dropdown
            label="Selecione o Cliente"
            options={clients.map((client) => ({
              value: client.id,
              label: client.name,
            }))}
            value={selectedClient}
            onChange={handleClientChange}
            required={true}
          />
        </div>

        {loading ? (
          <p>Carregando leituras...</p>
        ) : (
          <div className="readings-list">
            {readings.length > 0 ? (
              readings.map((reading, index) => (
                <div key={index} className="reading-item">
                  <p><strong>Unidade:</strong> {reading.unity}</p>
                  <p><strong>Bloco:</strong> {reading.block}</p>
                  <p><strong>Leitura:</strong> {reading.reading}</p>
                  <p><strong>Data:</strong> {reading.date}</p>
                  <p><strong>Quente ou Frio:</strong> {reading.hot_or_cold}</p>
                  <p>----------------------------------------------------------</p>
                </div>
              ))
            ) : (
              <p>Nenhuma leitura encontrada para o cliente selecionado.</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default InsertIndReadingPage;